<template>

<div>


  <!-- Filtros -->
  <folders-filters @foldersfilters-updated="filtersUpdated"
    :locationsList="locationsList"
    ref="foldersFiltersRef"/>

  <!-- Breadcrumb -->
  <div class="py-4">
    <span v-for="step, index in tree" :key="'breadcrumb_'+step.id">
      <span v-if="index < tree.length-1" @click="openFolder(step)" class="cursor-pointer"
        >{{ step.name }}<i class="fas fa-angle-right px-2"></i>
      </span>
      <span v-else>
        <b>{{ step.name }}</b>
      </span>
    </span>
  </div>


  <!-- Loading... -->
  <div v-if="isLoading" class="mt-4 text-center">
        <div class="mt-4 text-center"><scale-loader></scale-loader></div>
  </div>


  <div v-else>

    <!-- listado carpetas -->
    <div v-for="folder, index in folders" :key="'f_' + folder.id" >

      <div v-if="folder.id != -1 && (canMoveHere || !tree.slice(-1)[0].id)" class="w-full flex" :class="[ index % 2 ? 'bg-white' : 'bg-grey-lighter']">

        <div class="text-yellow cursor-pointer" @click="openFolder(folder)">
          <b-icon pack="fas" :icon="folder.icon || 'folder'" size="is-large" />
        </div>

        <div class="w-4/5 ml-2 flex items-center cursor-pointer" @click="openFolder(folder)">
            <span v-if="folder.id!=newFolder.id">{{ folder.name }}</span>
        </div>

      </div>

    </div>

    <!-- mensaje carpeta vacia -->
    <div v-if="folders.length == 0" class="p-4 text-gray-400">
       <b-icon pack="fas" icon="folder-open" />{{$t('no_folders')}} 
    </div>
    <div 
      v-if="canMoveHere && selectedEntityId" 
      @click="moveToFolder(tree.slice(-1)[0])" 
      class="text-green cursor-pointer flex items-center" 
      style="background: #cec;"
      >
      <div class="w-5/6 p-4">
        {{$t('move_to')}} <b>{{tree.slice(-1)[0].name}}</b> <b-icon pack="fas" icon="check-circle" />
      </div>
      <div class="w-1/6 p-2 m-2 font-bold rounded-xl text-center" style="border: 3px solid #38c172">
        {{$t('move')}} <b-icon pack="fas" icon="arrow-right" />
      </div>
    </div>
    <div 
      v-if="!canMoveHere && tree.slice(-1)[0].id" 
      class="p-4 text-red cursor-not-allowed" 
      style="background: #ecc;"
      >
      {{$t('move_same_folder')}} <b-icon pack="fas" icon="exclamation-circle" />
    </div>
  </div>


</div>

</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import EventBus from '~/plugins/bus'
  import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
  import foldersFilters from "·/components/documents-folders/folders-filters";

  export default {
    name: 'select-folders',
    middleware: 'auth',
    title: 'activities_page_title',
    subtitle: 'activities_page_subtitle',
    menuOption: '2-2',

    components: {
      ScaleLoader,
      foldersFilters,
    },

    props : {
      folderId: { type: Number, required: false },
      parentId: { type: Number, required: true },
      documentId: { type: [Number,Array], required: false },
    },

    computed: {
      ...mapGetters({
        user: "auth/user",
        project: 'app/project',
      }),
    },

    data: () => ({
      isLoading: true,
      isSave: false,
      tree: [{ id: null, name: 'Raiz', is_virtual: true }], // path de las carpetas que vamos abriendo
      currentFolder: {}, // carpeta seleccionada viendo sus ficheros
      folders: [], // carpetas (mezcla de DocumentFolder y Subactividades) de la carpeta actual
      foldersSub: [], // temporal con subcarpetas antes de añadirlas en folders
      currentActivity: {}, // carpeta virtual 'actividad' seleccionada
      total: 0,
      selectedEntityFolderTree: [], // arbol completo de la actividad seleccionada (para pasar a selector de arbol)
      selectedEntityId: 0, // id de la actividad donde cuelga la carpeta seleccionada (para pasar a selector arbol)
      lastSelectedFolder: {}, // la ultima carpeta que se ha abierto (parecido a currentFolder pero este mantiene todos los atributos)

      locationsList: [], // lista de emplazamientos (para filtro)
      entityType: 'activity',
      canAdd: false,

      canManageFolders: false,
      isProjectManager: false,
      canAddValidationFlow: false,
      canMoveHere: true,

      newFolder: {
        id: '',
        code: '',
        name: '',
      },
    }),

    mounted() {
      this.getActivitiesList(true)
    },

    created() {
      let self = this;
      EventBus.$on("refreshFolderDocuments", function(e) {
        e = e || null;
        let folder_parent_id = e == null ? self.lastSelectedFolder.id : e.folder_parent_id

        // si hay carpeta seleccionada (si estamos en raiz raiz no hace falta)
        if (self.lastSelectedFolder.id && (self.lastSelectedFolder.id == folder_parent_id) ) {
          self.openFolder(self.lastSelectedFolder)
        }

      });

      EventBus.$on('treeSelectorNodeSelected-ActivitySelected', function (node) {
        self.getActivityFolder(node)
      });

    },

    beforeDestroy(){
      EventBus.$off("refreshFolderDocuments");
      EventBus.$off("treeSelectorNodeSelected-ActivitySelected");
    },

    methods: {

      // listado inicial de carpetas, las actividades del proyecto
      async getActivitiesList(isFirstTime=false) {

        let args = {}

        if (this.$refs.foldersFiltersRef !== undefined) {
          args = this.$refs.foldersFiltersRef.generateFilters();
        }

        args.p = this.project.id
        args.with_location = 1
        args.pg = 1

        this.isLoading = true

        const {data} = await axios.get('/api/v2/activities', {params: args})

        // Last and total
        // this.last = data.lastPage
        this.total = data.total

        if (data && data.activities) {
          this.folders = data.activities.map(function(activity) {
            return {
                id: activity.id,
                name: activity.planning_code + ' - ' + activity.name,
                code: null,
                is_virtual: true,
                location: activity.location
            }
          })
          this.documents = []

        } else {
          this.$notify.error('error_loading_activities')
        }

        this.isLoading = false
      },


      // devuelve listado subactividades para pintarlas como carpetas virtuales de la actividad
      async getSubactivities(activityId) {
        let url = '/api/v2/activity/' + activityId + '/subactivities'
        let self = this
        await axios.get(url)
          .then(function (response) {

            if (response.data && response.data.subactivities) {
              self.foldersSub = response.data.subactivities.map(function(activity) {
                return {
                  id: activity.id,
                  name: activity.planning_code + ' - ' + activity.name,
                  code: null,
                  is_virtual: true
                }
              })

            }
          })
      },


      // puede ser carpeta virtual (una actividad) o carpeta de documentos dentro de la actividad
      openFolder(folder) {

        if( this.newFolder.id != '' )
          return

        this.lastSelectedFolder = folder
        this.setTree(folder)

        // caso base, abrir carpeta raiz raiz de todo
        if (folder.id === null && folder.is_virtual) {
          this.canManageFolders = false
          this.canAdd = false
          this.getActivitiesList()
          return
        }

        let loadSubactivities = false

        if (folder.is_virtual) {
          this.currentActivity.id = folder.id // carpeta ficticia actividad, abre documentFolders que cuelgan del raiz
          this.currentFolder.id = null // empezamos por la raiz
          // carga subcarpetas virtuales (las subactividades)
          loadSubactivities = true
        } else {
          this.currentFolder.id = folder.id //carpeta 'de verdad' documentFolder, abre lo que cuelga de este id
        }

        // carga documentos y carpetas que cuelgan de esta actividad/documentFolder
        this.loadDocumentsList(this.currentActivity.id, 'activity', this.currentFolder.id, loadSubactivities)

      },


      async loadDocumentsList(entityId, entityType, documentFolder=null, loadSubactivities=false) {

        this.isLoading = true
        let self = this

        let params = {
          with_folders: 1, // ordenado en carpetas
          folder_id: documentFolder, // docs y carpetas que cuelgan de esta carpeta (del raiz si es null)
        }

        await axios.get("/api/v2/"+ entityType +"/" + entityId + "/documents?-=1",
          { params: params })
          .then(function(response) {

            if (response.data && response.data.documents) {
              self.documents = response.data.documents.sort(self.mySortByName);
              self.folders = response.data.folders.map(function(folder) {
                  return {
                    id: folder.id,
                    name: folder.name,
                    code: folder.code,
                    is_virtual: false,
                    icon: folder.icon,
                    documents: folder.documents,
                    children: folder.children
                  }
                }).sort(self.mySortByName)

              self.total = response.data.total
              self.currentFolder = response.data.currentFolder || {}

              if (loadSubactivities) {

                self.getSubactivities(entityId).then(function(response) {
                  self.folders = self.folders.concat(self.foldersSub).sort(self.mySortByName)
                })

              }

              // Caso raiz de actividad, cargamos su arbol de carpetas completo para opcion "mover" fichero
              if (documentFolder == null && entityType == 'activity') {
                self.getActivityFolder(entityId)
              }

            }
          })
          .catch(function(error) {
            console.log(error);
          })
          .finally(function () {
            self.isLoading = false;
          });
      },

      // Establece el Breadcrumb
      setTree(folder) {

        let alreadyOpened = this.tree.some(function(value) { return value.id == folder.id })

        // ha pulsado en una carpeta superior ya abierta, borramos las carpetas por debajo
        if (alreadyOpened) {
          let position = this.tree.findIndex(value => { return value.id == folder.id})
          this.tree = this.tree.filter( (value, index) => { return index <= position })
        } else {
          // la carpeta es nueva en la ruta, insertarla
          this.tree.push({ id: folder.id, name: folder.name, is_virtual: folder.is_virtual || false })
        }
        this.canMoveHere = this.tree.slice(-1).some(folder => folder.id && (folder.id != this.folderId || this.selectedEntityId != this.parentId)) || (this.documentId && !this.folderId)
      },


      getActivityFolder(entityId) {
        let self = this;

        axios.get("/api/v2/activity/" + entityId + "/", { params: {with_folders: 1} })
            .then(function(response) {
              self.selectedEntityFolderTree = response.data.data.folders || []
              self.selectedEntityId = entityId
              self.canAdd = (response.data.data.is_creator || response.data.data.is_manager || response.data.data.is_participant || response.data.data.is_collaborator) && !(response.data.data.status == 6) && (self.currentActivity.id && self.currentActivity.id > 0)
              self.canManageFolders = (response.data.data.is_project_manager || response.data.data.is_manager) && ( self.currentActivity.id && self.currentActivity.id > 0)
              self.canAddValidationFlow = (response.data.data.is_project_manager) && ( self.currentActivity.id && self.currentActivity.id > 0)
              self.isProjectManager = response.data.data.is_project_manager
            })
      },


      // ordenar las carpetas por nombre
      mySortByName(a, b) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      },



      reloadList() {
        this.$emit('reload-documents-list')
      },


      isOk(document){
        return document.status == 1;
      },

      filtersUpdated() {
        this.getActivitiesList();
      },

      async moveToFolder(folder) {
        if (this.folderId) {
          return await this.moveFolder(folder)
        }else if (this.documentId) {
          return await this.moveDocument(folder)
        }
      },

      async moveFolder(folder) {
        let self = this;
        let body = {
          entity_type: 'activity',
          entity_id: self.selectedEntityId,
          folder_id: folder.is_virtual ? null : folder.id
        }
        const { data } = await axios.post("/api/v2/document-folder/"+self.folderId+"/move", body)
        if (data && data.success) {
          this.$notify.success('moved_folder')

        } else if(data && data.msg){
          this.$notify.error(data.msg)

        } else {
          this.$notify.error('moved_folder_error')
        }
        this.$emit('close')
      },

      async moveDocument(folder) {
        const params = {
          folder_id: folder.is_virtual ? 0 : folder.id,
          entity_id: this.selectedEntityId
        };

        let url = '';
        if (Array.isArray(this.documentId)) {
          // Si es un array, usamos el nuevo endpoint y mandamos document_ids
          params.document_ids = this.documentId;
          url = '/api/v2/documents/move-multiple-docs';
        } else {
          // Si es un único documento, usamos el endpoint clásico
          url = `/api/v2/document/${this.documentId}/move-folder`;
        }

        const { data } = await axios.post(url, params);

        if (data && data.success) {
          this.$notify.success('document_moved');
        } else if (data && data.msg) {
          this.$notify.error(data.msg);
        } else {
          this.$notify.error('document_moved_error');
        }

        this.$emit('close');
      }

    },
  };
</script>

<style>
</style>


